import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Image,
    List,
    ListItem,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Text,
    VStack,
    useTheme,
} from '@chakra-ui/react'
import { useGetPostsQuery } from '@core/app/api/postsApi'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'
import photo_placeholder from '@page/company/assets/images/photo.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import queryString from 'query-string'
import { HeaderInfo } from '@core/app/types/profileApiType'
import { useTranslation } from 'react-i18next'
import { Localization } from '@core/app/types/globalApiType'

const baseClass = 'PopularJobs-section'

type Content = {
    [key: number | string]: string
}

export const PopularJobs: FC<{ id: number; headerInfo?: HeaderInfo | undefined; languageId?: number }> = ({
    id,
    headerInfo,
    languageId,
}) => {
    const { appTheme } = useAppTheme()
    const { data: postsData } = useGetPostsQuery({})
    const theme = useTheme()
    const navigate = useNavigate()
    // const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [scheme, setScheme] = useState<Content>({})
    const { t } = useTranslation()
    const [buttonUrl, setButtonUrl] = useState<string>('/jobs')

    const isPhotosExist = !!(scheme.photo1 || scheme.photo2 || scheme.photo3)
    const isContentExist = Object.values(content).length > 0

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections || {}
        const section = landing
            ? Object.values(appTheme?.newSettings?.landing?.structure?.sections || {}).find(
                  (section) => section.name === 'Popular jobs' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#D3D3D3'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#E6E6E6'

        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingBackground}`
            : theme.colors.bg[5]

        schemeObject['buttonColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingButtonBackground}`
            : '#565656'
        schemeObject['buttonTextColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonText
            ? `#${appTheme?.newSettings.landing.colors.LandingButtonText}`
            : '#fff'

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['buttonColor']
                        break
                    case 'Heading':
                        updatedContent[field.id] = field.value
                        break
                    case 'Description':
                        updatedContent[field.id] = field.value
                        break
                    case 'Button text':
                        updatedContent[field.id] = field.value
                        break
                    case 'Button URL':
                        if (field.fieldTypeId === 7 && field.value) {
                            setButtonUrl(field.value)
                        }
                        break
                    case 'Photo 1':
                        schemeObject['photo1'] = field.value
                        break
                    case 'Photo 2':
                        schemeObject['photo2'] = field.value
                        break
                    case 'Photo 3':
                        schemeObject['photo3'] = field.value
                        break
                }
            })
            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const industries = Object.values(postsData?.data?.filters?.industry || {})

    const locations = Object.values(postsData?.data?.filters?.area || {})

    const employeeTypes = Object.values(postsData?.data?.filters?.jobType || {})

    // const getIndustries = () => {
    //     if (postsData && postsData?.data?.filters?.industry) {
    //         return Object.values(postsData?.data?.filters?.industry || {})
    //     }
    //
    //     return []
    // }
    //
    // const getLocations = () => {
    //     if (postsData && postsData?.data?.filters?.area) {
    //         return Object.values(postsData?.data?.filters?.area || {})
    //     }
    //
    //     return []
    // }
    //
    // const getEmployeeTypes = () => {
    //     if (postsData && postsData?.data?.filters?.jobType) {
    //         return Object.values(postsData?.data?.filters?.jobType || {})
    //     }
    //
    //     return []
    // }

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings?.landing?.structure?.translations || {}
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            // @ts-ignore
                            // item.languageId === langId ? (newContent[contentId] = item.value) : null
                            item.languageId === languageId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }

        setContent(newContent)
    }

    // useMemo(() => {
    //     if (headerInfo?.locale?.current) {
    //         const arr = Object.values(headerInfo?.locale?.current || {})
    //         if (arr.length) {
    //             setLangId(arr[0].id)
    //         }
    //     }
    // }, [headerInfo])

    const navigateToJobs = (id: number, type: 'country' | 'branch' | 'extent' | 'department') => {
        navigate({
            pathname: '/jobs',
            search: queryString.stringify(
                {
                    country: type === 'country' ? [id] : [],
                    branch: type === 'branch' ? [id] : [],
                    extent: type === 'extent' ? [id] : [],
                    department: type === 'department' ? [id] : [],
                },
                { arrayFormat: 'bracket' }
            ),
        })
    }

    const handleLinkClick = (url: string) => {
        window.location.href = url
    }
    const [localization, setLocalization] = useState<Localization>({})

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    return (
        <Box className={baseClass} as={'section'} py={{ base: '80px', xl: 30 }} bg={scheme.bg}>
            <Container maxW={{ base: 'container.landingSm', xl: 'container.xl' }}>
                <Flex
                    className={`${baseClass}__wrap`}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={{ base: 'column-reverse', xl: 'row' }}
                    gap={{ base: '40px', xl: 16 }}
                >
                    {isContentExist && (
                        <VStack
                            className={`${baseClass}__content`}
                            gap={6}
                            alignItems={'start'}
                            w={{ base: 'full', xl: isPhotosExist ? '592px' : undefined }}
                        >
                            {Object.values(content || {})[0] && (
                                <Heading fontSize={'32px'} fontWeight={'semibold'} color={scheme.textColor}>
                                    {Object.values(content)[0]}
                                </Heading>
                            )}
                            {Object.values(content || {})[1] && (
                                <Text fontSize={'sm'} lineHeight={6} maxW={'full'} color={'text.secondary'}>
                                    {Object.values(content || {})[1]}
                                </Text>
                            )}
                            <Button
                                variant={'secondary'}
                                bg={scheme.buttonColor}
                                color={scheme.buttonTextColor}
                                _hover={{ background: hexColorEffect(scheme.buttonColor, 30) }}
                                minW={'160px'}
                                onClick={() => {
                                    let finalUrl = buttonUrl
                                    const isValidUrl = (url: string): boolean => {
                                        return /^https?:\/\//i.test(url)
                                    }
                                    if (!isValidUrl(buttonUrl)) {
                                        if (!buttonUrl.startsWith('/') && buttonUrl.includes('.')) {
                                            finalUrl = `https://${buttonUrl}`
                                        } else {
                                            finalUrl = `${window.location.origin}${buttonUrl.startsWith('/') ? buttonUrl : `/${buttonUrl}`}`
                                        }
                                    }

                                    location.href = finalUrl
                                }}
                            >
                                {Object.values(content || {})[2] ? Object.values(content || {})[2] : 'Button'}
                            </Button>
                        </VStack>
                    )}
                    {isPhotosExist && (
                        <Box
                            className={`${baseClass}__photos`}
                            w={{ base: '343px', xl: '560px' }}
                            h={{ base: '196px', xl: '320px' }}
                            position={'relative'}
                        >
                            <Box
                                position={'absolute'}
                                right={{ base: '33px', xl: '55px' }}
                                top={0}
                                w={{ base: '196px', xl: '320px' }}
                                h={{ base: '196px', xl: '320px' }}
                                bg={'rgba(0, 0, 0, 0.04)'}
                                borderRadius={'full'}
                            />
                            {loaded ? (
                                scheme.photo1 && (
                                    <Image
                                        w={{ base: '85px', xl: '138px' }}
                                        h={{ base: '85px', xl: '138px' }}
                                        left={{ base: '21px', xl: '34px' }}
                                        bottom={{ base: '13px', xl: '22px' }}
                                        position={'absolute'}
                                        src={scheme.photo1}
                                        borderRadius={'full'}
                                        objectFit={'cover'}
                                    />
                                )
                            ) : (
                                <SkeletonCircle
                                    w={{ base: '85px', xl: '138px' }}
                                    h={{ base: '85px', xl: '138px' }}
                                    left={{ base: '21px', xl: '34px' }}
                                    bottom={{ base: '13px', xl: '22px' }}
                                    position={'absolute'}
                                />
                            )}
                            {loaded ? (
                                scheme.photo2 && (
                                    <Image
                                        w={{ base: '100px', xl: '164px' }}
                                        h={{ base: '100px', xl: '164px' }}
                                        position={'absolute'}
                                        left={{ base: '105px', xl: '172px' }}
                                        top={'0'}
                                        src={scheme.photo2}
                                        borderRadius={'full'}
                                        objectFit={'cover'}
                                    />
                                )
                            ) : (
                                <SkeletonCircle
                                    w={{ base: '100px', xl: '164px' }}
                                    h={{ base: '100px', xl: '164px' }}
                                    position={'absolute'}
                                    left={{ base: '105px', xl: '172px' }}
                                    top={'0'}
                                />
                            )}
                            {loaded ? (
                                scheme.photo3 && (
                                    <Image
                                        w={{ base: '118px', xl: '193px' }}
                                        h={{ base: '118px', xl: '193px' }}
                                        position={'absolute'}
                                        right={0}
                                        bottom={{ base: '10px', xl: '1px' }}
                                        src={scheme.photo3}
                                        borderRadius={'full'}
                                        objectFit={'cover'}
                                    />
                                )
                            ) : (
                                <SkeletonCircle
                                    w={{ base: '118px', xl: '193px' }}
                                    h={{ base: '118px', xl: '193px' }}
                                    position={'absolute'}
                                    right={0}
                                    bottom={{ base: '10px', xl: '1px' }}
                                />
                            )}

                            <Box
                                position={'absolute'}
                                left={{ base: '97px', xl: '159px' }}
                                top={{ base: '61px', xl: '100px' }}
                                w={{ base: '31px', xl: '52px' }}
                                h={{ base: '31px', xl: '52px' }}
                                bg={scheme.secondaryColor}
                                borderRadius={'full'}
                            />
                            <Box
                                position={'absolute'}
                                left={{ base: '23px', xl: '38px' }}
                                bottom={{ base: '10px', xl: '17px' }}
                                w={{ base: '9px', xl: '14px' }}
                                h={{ base: '9px', xl: '14px' }}
                                bg={scheme.secondaryColor}
                                borderRadius={'full'}
                            />
                            <Box
                                position={'absolute'}
                                right={{ base: '-5px', xl: '-10px' }}
                                bottom={{ base: '26px', xl: '43px' }}
                                w={{ base: '24px', xl: '40px' }}
                                h={{ base: '24px', xl: '40px' }}
                                bg={scheme.primaryColor}
                                borderRadius={'full'}
                            />
                        </Box>
                    )}
                </Flex>
                {locations.length > 0 && industries.length > 0 && employeeTypes.length > 0 && (
                    <Flex
                        className={`${baseClass}__filters`}
                        alignItems={'start'}
                        flexDirection={{ base: 'column', xl: 'row' }}
                        gap={{ base: '40px', xl: 8 }}
                        mt={10}
                    >
                        <Box w={'full'}>
                            <Heading
                                fontSize={'xl'}
                                lineHeight={7}
                                fontWeight={'semibold'}
                                color={'text.main'}
                                pb={4}
                                borderBottom={'1px solid'}
                                borderColor={'border.2'}
                                mb={6}
                            >
                                {t('189')}
                            </Heading>
                            <List
                                display={'flex'}
                                flexDirection={'column'}
                                gap={6}
                                fontWeight={'medium'}
                                color={'text.main'}
                            >
                                {industries.map((industry) => (
                                    <ListItem
                                        key={industry.id}
                                        cursor={'pointer'}
                                        onClick={() => handleLinkClick(`/jobs?industry[]=${industry.id}`)}
                                        _hover={{ textDecoration: 'underline' }}
                                    >
                                        {industry.name}{' '}
                                        <Text as={'span'} color={'text.thirdly'}>
                                            ({industry.totalCount})
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box w={'full'}>
                            <Heading
                                fontSize={'xl'}
                                lineHeight={7}
                                fontWeight={'semibold'}
                                color={'text.main'}
                                pb={4}
                                borderBottom={'1px solid'}
                                borderColor={'border.2'}
                                mb={6}
                            >
                                {t('2414')}
                            </Heading>
                            <List
                                display={'flex'}
                                flexDirection={'column'}
                                gap={6}
                                fontWeight={'medium'}
                                color={'text.main'}
                            >
                                {locations.map((location) => (
                                    <ListItem
                                        key={location.id}
                                        cursor={'pointer'}
                                        onClick={() => handleLinkClick(`/jobs?country[]=${location.id}`)}
                                        _hover={{ textDecoration: 'underline' }}
                                    >
                                        {location.name}{' '}
                                        <Text as={'span'} color={'text.thirdly'}>
                                            ({location.totalCount})
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box w={'full'}>
                            <Heading
                                fontSize={'xl'}
                                lineHeight={7}
                                fontWeight={'semibold'}
                                color={'text.main'}
                                pb={4}
                                borderBottom={'1px solid'}
                                borderColor={'border.2'}
                                mb={6}
                            >
                                {localization[15] ? localization[15] : t('331')}
                            </Heading>
                            <List
                                display={'flex'}
                                flexDirection={'column'}
                                gap={6}
                                fontWeight={'medium'}
                                color={'text.main'}
                            >
                                {employeeTypes.map((empType) => (
                                    <ListItem
                                        key={empType.id}
                                        cursor="pointer"
                                        onClick={() => handleLinkClick(`/jobs?jobType[]=${empType.id}`)}
                                        _hover={{ textDecoration: 'underline' }}
                                    >
                                        {empType.name}{' '}
                                        <Text as={'span'} color={'text.thirdly'}>
                                            ({empType.totalCount})
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Flex>
                )}
            </Container>
        </Box>
    )
}
