import { useMediaQuery } from '@chakra-ui/react'
import type { HeaderInfo } from '@core/app/types/profileApiType'
import { Footer } from '@core/components/Footer/Footer'
import { Header } from '@core/components/Header/Header'
import { MobileHeader } from '@core/components/mobileHeader/mobileHeader'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { About } from '@page/company/components/Sections/About'
import { Advantages } from '@page/company/components/Sections/Advantages'
import { Hero } from '@page/company/components/Sections/Hero'
import { Jobs } from '@page/company/components/Sections/Jobs'
import { Movie } from '@page/company/components/Sections/Movie'
import { PopularJobs } from '@page/company/components/Sections/PopularJobs'
import { Quote } from '@page/company/components/Sections/Quote'
import { Recruiters } from '@page/company/components/Sections/Recruiters'
import { useMemo, useState } from 'react'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'

export const Constructor = () => {
    const { appTheme } = useAppTheme()
    const [isDesktop] = useMediaQuery('(min-width: 1024px)')
    const { data: headerInfo, isLoading } = useGetHeaderInfoQuery({})
    const [isFeedbackMenu, setIsFeedbackMenu] = useState(false)
    const [selectedFeedback, setSelectedFeedback] = useState<number | null>(null)

    const memoTheme = useMemo(() => {
        return appTheme
    }, [appTheme])

    const isSectionsExist =
        appTheme?.newSettings?.landing &&
        appTheme?.newSettings.landing.structure &&
        appTheme.newSettings.landing.structure.sections &&
        Object.keys(appTheme.newSettings?.landing?.structure?.sections || {}).length > 0

    const currentLocaleId = Object.values(headerInfo?.locale?.current || {})[0]?.id || appTheme?.languageId

    const renderSection = (name: string, id: number) => {
        switch (name) {
            case 'Header':
                return (
                    <Header
                        key={name}
                        // fixedHeader={true}
                        isFeedbackMenu={isFeedbackMenu}
                        setIsFeedbackMenu={setIsFeedbackMenu}
                        selectedFeedback={selectedFeedback}
                        setSelectedFeedback={setSelectedFeedback}
                    />
                )
            case 'Hero':
                return <Hero key={name} id={id} headerInfo={headerInfo} languageId={currentLocaleId} />
            case 'Video':
                return <Movie key={name} id={id} />
            case 'About':
                return <About key={name} id={id} languageId={currentLocaleId} />
            case 'Popular jobs':
                return <PopularJobs key={name} id={id} languageId={currentLocaleId} />
            case 'Advantages':
                return <Advantages key={name} id={id} languageId={currentLocaleId} />
            case 'Quote':
                return <Quote key={name} id={id} languageId={currentLocaleId} />
            case 'Recruiters':
                return <Recruiters key={name} id={id} languageId={currentLocaleId} />
            case 'Jobs':
                return <Jobs key={name} id={id} languageId={currentLocaleId} />
            case 'Footer':
                return <Footer key={name} />
            case 'MobileHeader':
                return (
                    !isDesktop && (
                        <MobileHeader
                            isFeedbackMenu={isFeedbackMenu}
                            setIsFeedbackMenu={setIsFeedbackMenu}
                            selectedFeedback={selectedFeedback}
                            setSelectedFeedback={setSelectedFeedback}
                        />
                    )
                )
            default:
                return null
        }
    }

    if (!isSectionsExist) {
        return <Header />
    }

    if (memoTheme && isSectionsExist) {
        return (
            <>
                {[
                    { name: 'Header', id: 0 },
                    ...Object.values(memoTheme?.newSettings?.landing?.structure?.sections || {}).map((item) => ({
                        name: item.name,
                        id: item.id,
                    })),
                    { name: 'Footer', id: 0 },
                    { name: 'MobileHeader', id: 0 },
                ].map((item) => renderSection(item.name, item.id))}
            </>
        )
    }
    return null
}
