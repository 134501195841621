import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Image,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Text,
    VStack,
    useTheme,
} from '@chakra-ui/react'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'
import photo_placeholder from '@page/company/assets/images/photo.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { HeaderInfo } from '@core/app/types/profileApiType'

const baseClass = 'Hero-section'

type Content = {
    [key: number | string]: string
}

export const Hero: FC<{ id: number; headerInfo: HeaderInfo | undefined; languageId?: number }> = ({
    id,
    headerInfo,
    languageId,
}) => {
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    // const [langId, setLangId] = useState<number>();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [scheme, setScheme] = useState<Content>({})
    const [buttonUrl, setButtonUrl] = useState<string>('/jobs')

    const isPhotosExist = useMemo(() => {
        return !!(scheme.photo1 || scheme.photo2 || scheme.photo3)
    }, [scheme])

    const isContentExist = useMemo(() => {
        return !!Object.values(content).length
    }, [content])

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections || {}
        const section = landing
            ? Object.values(landing).find((section) => section.name === 'Hero' && section.id === id)
            : null

        const fields = section ? section.fields : null
        schemeObject['logo'] = appTheme?.headerLogo || ''
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#393939'
        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundDark1
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundDark1}`
            : theme.colors.bg[6]
        schemeObject['bgSphere'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundDark2
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundDark2}`
            : theme.colors.bg[6]
        schemeObject['buttonColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingButtonBackground}`
            : '#565656'
        schemeObject['buttonTextColor'] = appTheme?.newSettings?.landing?.colors?.LandingButtonText
            ? `#${appTheme?.newSettings.landing.colors.LandingButtonText}`
            : '#fff'

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['buttonColor']
                        break
                    case 'Heading':
                        schemeObject['heading'] = field.value
                        updatedContent[field.id] = field.value
                        break
                    case 'Description':
                        schemeObject['description'] = field.value
                        updatedContent[field.id] = field.value
                        break
                    case 'Button text':
                        schemeObject['buttonText'] = field.value
                        updatedContent[field.id] = field.value
                        break
                    case 'Button URL':
                        if (field.fieldTypeId === 7 && field.value) {
                            setButtonUrl(field.value)
                        }
                        break
                    case 'Photo 1':
                        schemeObject['photo1'] = field.value
                        break
                    case 'Photo 2':
                        schemeObject['photo2'] = field.value
                        break
                    case 'Photo 3':
                        schemeObject['photo3'] = field.value
                        break
                }
            })
            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings?.landing?.structure?.translations || {}
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            item.languageId === languageId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }
        setContent(newContent)
    }

    // useEffect(() => {
    //     if (headerInfo?.locale?.current) {
    //         const arr = Object.values(headerInfo?.locale?.current || {});
    //         if (arr.length) {
    //             setLangId(arr[0].id);
    //         }
    //     }
    // }, [headerInfo]);

    return (
        <Box
            className={baseClass}
            as={'section'}
            pt={{ base: '80px', xl: '108px' }}
            //https://app.asana.com/0/1202798436891149/1209490258848234
            //Reducing the size by 30%, hopefully, this is what we want. The old value was 105. (128)
            pb={{ base: '80px', xl: '70px' }}
            bg={scheme.bg}
        >
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                <Flex
                    w={'full'}
                    alignItems={'center'}
                    flexDirection={{ base: 'column-reverse', xl: 'row' }}
                    gap={{ base: '40px', xl: '100px' }}
                    // justifyContent={'space-between'}
                    justifyContent={'center'}
                    className={`${baseClass}__wrap`}
                >
                    {isContentExist && (
                        <VStack
                            className={`${baseClass}__content`}
                            w={'full'}
                            maxW={{ base: 'full', xl: isPhotosExist ? '488px' : undefined }}
                            gap={6}
                            alignItems={isPhotosExist ? 'start' : 'center'}
                        >
                            {Object.values(content || {})[0] && (
                                <Heading
                                    color={scheme.textColor}
                                    maxW={{ base: '335px', xl: '488px' }}
                                    //Changing the size due to the request in this task. (old value 36 / 40)
                                    //https://app.asana.com/0/1202798436891149/1209490258848234
                                    fontSize={{ base: '32px', xl: '32px' }}
                                >
                                    {Object.values(content || {})[0]}
                                </Heading>
                            )}
                            {Object.values(content)[1] && (
                                <Text
                                    color={scheme.textColor}
                                    fontSize={'sm'}
                                    lineHeight={6}
                                    maxW={{ base: 'full', xl: isPhotosExist ? '488px' : undefined }}
                                >
                                    {Object.values(content || {})[1]}
                                </Text>
                            )}
                            <Button
                                variant={'primary'}
                                minW={'160px'}
                                bg={scheme.buttonColor}
                                color={scheme.buttonTextColor}
                                _hover={{ backgroundColor: hexColorEffect(scheme.buttonColor, 30) }}
                                onClick={() => {
                                    let finalUrl = buttonUrl
                                    const isValidUrl = (url: string): boolean => {
                                        return /^https?:\/\//i.test(url)
                                    }
                                    if (!isValidUrl(buttonUrl)) {
                                        if (!buttonUrl.startsWith('/') && buttonUrl.includes('.')) {
                                            finalUrl = `https://${buttonUrl}`
                                        } else {
                                            finalUrl = `${window.location.origin}${buttonUrl.startsWith('/') ? buttonUrl : `/${buttonUrl}`}`
                                        }
                                    }

                                    location.href = finalUrl
                                }}
                            >
                                {Object.values(content)[2] ? Object.values(content)[2] : 'Button'}
                            </Button>
                        </VStack>
                    )}
                    {isPhotosExist && (
                        <Box
                            className={`${baseClass}__photos`}
                            position={'relative'}
                            w={{ base: '343px', xl: '624px' }}
                            h={{ base: '216px', xl: '392px' }}
                        >
                            <Box
                                position={'absolute'}
                                top={0}
                                right={0}
                                w={{ base: '185px', xl: '338px' }}
                                h={{ base: '185px', xl: '338px' }}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                bg={scheme.bg}
                                borderRadius={'full'}
                                overflow={'hidden'}
                            >
                                {loaded ? (
                                    scheme.photo3 && (
                                        <Box
                                            w={{ base: '185px', xl: '338px' }}
                                            h={{ base: '185px', xl: '338px' }}
                                            bg={scheme.bgSphere}
                                            borderRadius={'full'}
                                            overflow={'hidden'}
                                        >
                                            <Image w={'full'} h={'full'} objectFit={'cover'} src={scheme.photo3} />
                                        </Box>
                                    )
                                ) : (
                                    <SkeletonCircle w={'full'} h={'full'} />
                                )}
                            </Box>
                            <Box
                                position={'absolute'}
                                w={{ base: '29px', xl: '52px' }}
                                h={{ base: '29px', xl: '52px' }}
                                right={{ base: '120px', xl: '218px' }}
                                bottom={{ base: '25px', xl: '44px' }}
                                bg={scheme.secondaryColor}
                                borderRadius={'full'}
                            />
                            <Box
                                position={'absolute'}
                                w={{ base: '8px', xl: '14px' }}
                                h={{ base: '8px', xl: '14px' }}
                                right={{ base: '145px', xl: '265px' }}
                                bottom={{ base: '5px', xl: '18px' }}
                                bg={scheme.secondaryColor}
                                borderRadius={'full'}
                            />
                            {loaded ? (
                                scheme.photo1 && (
                                    <Image
                                        position={'absolute'}
                                        src={scheme.photo1}
                                        top={'8px'}
                                        left={0}
                                        w={{ base: '105px', xl: '192px' }}
                                        h={{ base: '105px', xl: '192px' }}
                                        zIndex={2}
                                        borderRadius={'full'}
                                        overflow={'hidden'}
                                        objectFit={'cover'}
                                    />
                                )
                            ) : (
                                <SkeletonCircle
                                    top={'8px'}
                                    left={0}
                                    position={'absolute'}
                                    w={{ base: '105px', xl: '192px' }}
                                    h={{ base: '105px', xl: '192px' }}
                                    zIndex={2}
                                />
                            )}

                            <Box
                                position={'absolute'}
                                top={{ base: '18px', xl: '33px' }}
                                left={{ base: '12px', xl: '24px' }}
                                w={{ base: '98px', xl: '179px' }}
                                h={{ base: '98px', xl: '179px' }}
                                bg={scheme.primaryColor}
                                borderRadius={'full'}
                                overflow={'hidden'}
                            />

                            {loaded ? (
                                scheme.photo2 && (
                                    <Image
                                        position={'absolute'}
                                        bottom={0}
                                        borderRadius={'full'}
                                        overflow={'hidden'}
                                        left={{ base: '66px', xl: '130px' }}
                                        w={{ base: '75px', xl: '136px' }}
                                        h={{ base: '75px', xl: '136px' }}
                                        src={scheme.photo2}
                                        objectFit={'cover'}
                                    />
                                )
                            ) : (
                                <SkeletonCircle
                                    position={'absolute'}
                                    bottom={0}
                                    left={{ base: '66px', xl: '130px' }}
                                    w={{ base: '75px', xl: '136px' }}
                                    h={{ base: '75px', xl: '136px' }}
                                />
                            )}
                        </Box>
                    )}
                </Flex>
            </Container>
        </Box>
    )
}
