import { Box, Container, Flex, HStack, Heading, Image, Skeleton, Text, useMediaQuery, useTheme } from '@chakra-ui/react'
import { PersonItem } from '@core/app/types/globalApiType'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import emailIcon from '@page/company/assets/images/email.svg'
import linkedinIcon from '@page/company/assets/images/linkedin.svg'
import photo from '@page/company/assets/images/photo_lighter.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HeaderInfo } from '@core/app/types/profileApiType'

const baseClass = 'Recruiters-section'

type Content = {
    [key: number | string]: string
}

export const Recruiters: FC<{ id: number; headerInfo?: HeaderInfo | undefined; languageId?: number }> = ({
    id,
    headerInfo,
    languageId,
}) => {
    const { appTheme, isExpired } = useAppTheme()
    const theme = useTheme()
    // const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [isLg] = useMediaQuery('(min-width: 960px)', {
        fallback: false,
    })
    const [persons, setPersons] = useState<PersonItem[]>([])
    const [scheme, setScheme] = useState<Content>({})
    const [searchParams] = useSearchParams()
    const token = searchParams.get('preview_token') || undefined

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings.landing.structure.sections
        const section = landing
            ? Object.values(appTheme?.newSettings.landing.structure.sections).find(
                  (section) => section.name === 'Recruiters' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings.landing.colors.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings.landing.colors.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#393939'
        schemeObject['bg'] = appTheme?.newSettings.landing.colors.LandingBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingBackground}`
            : theme.colors.bg[5]
        schemeObject['buttonTextColor'] = appTheme?.newSettings.landing.colors.LandingButtonText
            ? `#${appTheme?.newSettings.landing.colors.LandingButtonText}`
            : '#fff'
        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['primaryColor']
                        break
                    case 'Heading':
                        updatedContent[field.id] = field.value
                        break
                }
            })
            const recruiterIds = fields
                ? fields
                      .filter((field) => field.alias.startsWith('Recruiter'))
                      .map((field) => field.resourceId)
                      .filter((id) => id !== 0)
                : []
            const recruiters = Object.values(appTheme.newSettings.landing.structure.persons)
                .filter((person: PersonItem) => recruiterIds.includes(person.UserID))
                .slice(0, 4)

            if (recruiters.length > 0) {
                setPersons(recruiters)
            } else {
                setPersons([])
            }
            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings.landing.structure.translations
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent).forEach((contentId) => {
                Object.entries(translations).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            item.languageId === languageId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }

        setContent(newContent)
    }

    // useMemo(() => {
    //     if (headerInfo?.locale?.current) {
    //         setLangId(Object.values(headerInfo?.locale?.current)[0].id)
    //     }
    // }, [headerInfo])

    return (
        <Box className={baseClass} as={'section'} py={{ base: '80px', xl: '108px' }} bg={scheme.bg}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                {!loaded ? (
                    <Skeleton
                        speed={0}
                        w={{ base: '220px', xl: '350px' }}
                        h={'36px'}
                        borderRadius={'4px'}
                        mx={persons.length === 4 ? 0 : 'auto'}
                        bg={'#D3D3D3'}
                    ></Skeleton>
                ) : (
                    Object.values(content)[0] && (
                        <Heading
                            size={'xl'}
                            fontWeight={'semibold'}
                            color={scheme.textColor}
                            textAlign={persons.length === 4 ? 'left' : 'center'}
                        >
                            {Object.values(content || {})[0]}
                        </Heading>
                    )
                )}
                {isLg ? (
                    <HStack
                        mt={6}
                        gap={8}
                        justifyContent={persons.length === 4 || persons.length === 0 ? 'flex-start' : 'center'}
                    >
                        {persons.length > 0 &&
                            persons.map((person) => (
                                <Box
                                    bg={'bg.1'}
                                    w={'280px'}
                                    h={'364px'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    borderRadius={'8px'}
                                    py={8}
                                    px={6}
                                    key={person.UserID}
                                    boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
                                >
                                    {person.Image ? (
                                        <Box maxW={'136px'} borderRadius={'full'}>
                                            {Image && (
                                                <Image
                                                    src={person.Image}
                                                    w={'136px'}
                                                    h={'136px'}
                                                    borderRadius={'full'}
                                                    objectFit={'cover'}
                                                />
                                            )}
                                        </Box>
                                    ) : (
                                        <Box
                                            w={'136px'}
                                            h={'136px'}
                                            borderRadius={'full'}
                                            bg={'#F5F5F5'}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Image src={photo} />
                                        </Box>
                                    )}
                                    {person.Name && (
                                        <Text
                                            fontSize={'lg'}
                                            fontWeight={'semibold'}
                                            textAlign={'center'}
                                            color={'text.main'}
                                            mt={4}
                                            maxW={'full'}
                                        >
                                            {person.Name}
                                        </Text>
                                    )}

                                    {person.Title && (
                                        <Text fontSize={'sm'} textAlign={'center'} color={'text.thirdly'} mt={2}>
                                            {person.Title}
                                        </Text>
                                    )}
                                    {person.Linkedin || person.Email ? (
                                        <Box
                                            mt={'auto'}
                                            pt={7}
                                            borderTop={'1px solid'}
                                            borderColor={'border.1'}
                                            w={'full'}
                                        >
                                            <Flex justifyContent={'space-between'} maxW={14} mx={'auto'}>
                                                {person.Linkedin && (
                                                    <Box as={'a'} href={person.Linkedin} target={'_blank'}>
                                                        <Image src={linkedinIcon} />
                                                    </Box>
                                                )}
                                                {person.Email && (
                                                    <Box as={'a'} href={`mailto:${person.Email}`} target={'_blank'}>
                                                        <Image src={emailIcon} />
                                                    </Box>
                                                )}
                                            </Flex>
                                        </Box>
                                    ) : null}
                                </Box>
                            ))}
                    </HStack>
                ) : (
                    <Box mt={6}>
                        <Swiper
                            className={`${baseClass}__swiper`}
                            slidesPerView={1.5}
                            spaceBetween={16}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                            }}
                        >
                            {persons.length > 0
                                ? persons.map((person) => (
                                      <SwiperSlide key={person.UserID}>
                                          <Box
                                              bg={'bg.1'}
                                              w={{ base: '240px', sm: 'full', xl: '280px' }}
                                              h={'364px'}
                                              display={'flex'}
                                              flexDirection={'column'}
                                              alignItems={'center'}
                                              borderRadius={'8px'}
                                              py={8}
                                              px={6}
                                          >
                                              {person.Image ? (
                                                  <Box maxW={'136px'} borderRadius={'full'}>
                                                      {Image && (
                                                          <Image
                                                              src={person.Image}
                                                              w={'136px'}
                                                              h={'136px'}
                                                              borderRadius={'full'}
                                                              objectFit={'cover'}
                                                          />
                                                      )}
                                                  </Box>
                                              ) : (
                                                  <Box
                                                      w={'136px'}
                                                      h={'136px'}
                                                      borderRadius={'full'}
                                                      bg={'#F5F5F5'}
                                                      display={'flex'}
                                                      justifyContent={'center'}
                                                      alignItems={'center'}
                                                  >
                                                      <Image src={photo} />
                                                  </Box>
                                              )}
                                              {person.Name && (
                                                  <Text
                                                      fontSize={'lg'}
                                                      fontWeight={'semibold'}
                                                      textAlign={'center'}
                                                      color={'text.main'}
                                                      mt={4}
                                                  >
                                                      {person.Name}
                                                  </Text>
                                              )}

                                              {person.Title && (
                                                  <Text
                                                      fontSize={'sm'}
                                                      textAlign={'center'}
                                                      color={'text.thirdly'}
                                                      mt={2}
                                                  >
                                                      {person.Title}
                                                  </Text>
                                              )}
                                              {person.Linkedin || person.Email ? (
                                                  <Box
                                                      mt={'auto'}
                                                      pt={7}
                                                      borderTop={'1px solid'}
                                                      borderColor={'border.1'}
                                                      w={'full'}
                                                  >
                                                      <Flex justifyContent={'space-between'} maxW={14} mx={'auto'}>
                                                          {person.Linkedin && (
                                                              <Box as={'a'} href={person.Linkedin} target={'_blank'}>
                                                                  <Image src={linkedinIcon} />
                                                              </Box>
                                                          )}
                                                          {person.Email && (
                                                              <Box
                                                                  as={'a'}
                                                                  href={`mailto:${person.Email}`}
                                                                  target={'_blank'}
                                                              >
                                                                  <Image src={emailIcon} />
                                                              </Box>
                                                          )}
                                                      </Flex>
                                                  </Box>
                                              ) : null}
                                          </Box>
                                      </SwiperSlide>
                                  ))
                                : Array.from(Array(4).keys()).map((_, index) => (
                                      <SwiperSlide key={index}>
                                          <Box
                                              bg={'bg.1'}
                                              w={{ base: '240px', sm: 'full', xl: '280px' }}
                                              h={'364px'}
                                              display={'flex'}
                                              flexDirection={'column'}
                                              alignItems={'center'}
                                              borderRadius={'8px'}
                                              py={8}
                                              px={6}
                                          >
                                              <Box
                                                  w={'136px'}
                                                  h={'136px'}
                                                  borderRadius={'full'}
                                                  bg={'#F5F5F5'}
                                                  display={'flex'}
                                                  justifyContent={'center'}
                                                  alignItems={'center'}
                                              >
                                                  <Image src={photo} />
                                              </Box>
                                              <Text
                                                  fontSize={'lg'}
                                                  fontWeight={'semibold'}
                                                  textAlign={'center'}
                                                  color={'text.main'}
                                                  mt={4}
                                              >
                                                  Name
                                              </Text>
                                              <Text fontSize={'sm'} textAlign={'center'} color={'text.thirdly'} mt={2}>
                                                  Position
                                              </Text>
                                              <Box
                                                  mt={'auto'}
                                                  pt={7}
                                                  borderTop={'1px solid'}
                                                  borderColor={'border.1'}
                                                  w={'full'}
                                              >
                                                  <Flex justifyContent={'space-between'} maxW={14} mx={'auto'}>
                                                      <Box w={6} h={6} bg={'#E6E6E6'} borderRadius={'full'} />
                                                      <Box w={6} h={6} bg={'#BBBBBB'} borderRadius={'full'} />
                                                  </Flex>
                                              </Box>
                                          </Box>
                                      </SwiperSlide>
                                  ))}
                        </Swiper>
                    </Box>
                )}
            </Container>
        </Box>
    )
}
