import {
    Box,
    Button,
    Flex,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
    useMediaQuery,
} from '@chakra-ui/react'
import { useLogoutMutation } from '@core/app/api/authApi'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { setResetForm } from '@core/app/slices/profile'
import { IconMenu } from '@core/assets/icons/menu'
import { IconJobPositions } from '@core/assets/icons/positions'
import { IconProfile } from '@core/assets/icons/profile'
import { useAppDispatch } from '@core/utils/hooks/hook'
import { useTranslation } from 'react-i18next'
import { Localization } from '@core/app/types/globalApiType'
import { useEffect, useState } from 'react'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'

interface Props {
    useMobileHeader?: boolean
    isFeedbackMenu?: boolean
    setIsFeedbackMenu?: (val: boolean) => void
    selectedFeedback?: number | null
    setSelectedFeedback?: (val: number | null) => void
}

export const MobileHeader = ({
    useMobileHeader = true,
    isFeedbackMenu = false,
    selectedFeedback = null,
    setIsFeedbackMenu = (v) => {},
    setSelectedFeedback = (v) => {},
}: Props) => {
    const dispatch = useAppDispatch()
    const { data: headerInfo } = useGetHeaderInfoQuery({})
    const [logout] = useLogoutMutation()
    const { t } = useTranslation()
    const [localization, setLocalization] = useState<Localization>({})
    const { appTheme } = useAppTheme()

    const [isDesktop] = useMediaQuery('min-width: 1230px')

    const logoutHandler = async () => {
        try {
            const response = await logout().unwrap()

            localStorage.removeItem('mobile_verification')

            location.href = response.redirectUrl
            localStorage.removeItem('apply_dialog')
            localStorage.removeItem('service_code')
            localStorage.removeItem('is_logged')
            dispatch(setResetForm(true))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    if (headerInfo?.data?.FirstName && useMobileHeader) {
        return (
            <Box
                className={'MobileHeader'}
                position={'fixed'}
                left={0}
                bottom={0}
                right={0}
                w={'full'}
                h={'83px'}
                bg={'bg.1'}
                border={'1px solid'}
                borderColor={'border.1'}
                px={4}
                zIndex={'999'}
            >
                <Flex pt={3} justifyContent={'space-between'}>
                    <Link href={'/jobs'} display={'block'} w={'75px'} h={'50px'}>
                        <VStack>
                            <IconJobPositions fill={'#4f4f4f'} />
                            <Text fontSize={'10px'} fontWeight={'medium'} color={'text.secondary'}>
                                {localization[23] ? localization[23] : t('2495')}
                            </Text>
                        </VStack>
                    </Link>
                    {headerInfo && headerInfo?.data.CandidateID && (
                        <Link href={'/profile'} display={'block'} w={'75px'} h={'50px'}>
                            <VStack>
                                <IconProfile fill={'#4f4f4f'} />
                                <Text fontSize={'10px'} fontWeight={'medium'} color={'text.secondary'}>
                                    {t('4647')}
                                </Text>
                            </VStack>
                        </Link>
                    )}

                    {headerInfo?.data.CandidateID && (
                        <Popover
                            isLazy
                            placement={'bottom-end'}
                            autoFocus={false}
                            arrowPadding={8}
                            closeOnBlur={true}
                            trigger={'hover'}
                        >
                            <PopoverTrigger>
                                <Button variant={'link'}>
                                    <VStack w={'75px'} h={'50px'}>
                                        <IconMenu fill={'#4f4f4f'} />
                                        <Text fontSize={'10px'} fontWeight={'medium'} color={'text.secondary'}>
                                            {t('397')}
                                        </Text>
                                    </VStack>
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent mb={2} maxW={'200px'}>
                                <PopoverArrow />
                                <PopoverBody>
                                    {headerInfo?.menu.applications && (
                                        <Box
                                            as={'a'}
                                            display={'block'}
                                            href={'/profile/applications'}
                                            py={1.5}
                                            px={3}
                                            _hover={{
                                                backgroundColor: '#F5F5F5',
                                            }}
                                            cursor={'pointer'}
                                            borderRadius={'4px'}
                                        >
                                            <Text
                                                fontSize={'sm'}
                                                fontWeight={'medium'}
                                                color={'text.secondary'}
                                                textTransform={'capitalize'}
                                            >
                                                {t('3180')}
                                            </Text>
                                        </Box>
                                    )}
                                    {headerInfo?.menu.agreements && (
                                        <Box
                                            as={'a'}
                                            display={'block'}
                                            href={'/profile/agreement'}
                                            py={1.5}
                                            px={3}
                                            _hover={{
                                                backgroundColor: '#F5F5F5',
                                            }}
                                            cursor={'pointer'}
                                            borderRadius={'4px'}
                                        >
                                            <Text
                                                fontSize={'sm'}
                                                fontWeight={'medium'}
                                                color={'text.secondary'}
                                                textTransform={'capitalize'}
                                            >
                                                {t('3339')}
                                            </Text>
                                        </Box>
                                    )}
                                    {headerInfo?.menu?.newsfeed && (
                                        <Box
                                            as={'a'}
                                            display={'block'}
                                            href={'/newsfeed'}
                                            py={1.5}
                                            px={3}
                                            _hover={{
                                                backgroundColor: '#F5F5F5',
                                            }}
                                            cursor={'pointer'}
                                            borderRadius={'4px'}
                                        >
                                            <Text
                                                fontSize={'sm'}
                                                fontWeight={'medium'}
                                                color={'text.secondary'}
                                                textTransform={'capitalize'}
                                            >
                                                {t('5137')}
                                            </Text>
                                        </Box>
                                    )}
                                    {headerInfo?.menu?.contactUs && (
                                        <Box
                                            as={'a'}
                                            display={'block'}
                                            href={'/contacts'}
                                            py={1.5}
                                            px={3}
                                            _hover={{
                                                backgroundColor: '#F5F5F5',
                                            }}
                                            cursor={'pointer'}
                                            borderRadius={'4px'}
                                        >
                                            <Text
                                                fontSize={'sm'}
                                                fontWeight={'medium'}
                                                color={'text.secondary'}
                                                textTransform={'capitalize'}
                                            >
                                                {t('2762')}
                                            </Text>
                                        </Box>
                                    )}
                                    <Box
                                        as={'a'}
                                        display={'block'}
                                        href={'/profile/settings'}
                                        py={1.5}
                                        px={3}
                                        _hover={{
                                            backgroundColor: '#F5F5F5',
                                        }}
                                        cursor={'pointer'}
                                        borderRadius={'4px'}
                                    >
                                        <Text
                                            fontSize={'sm'}
                                            fontWeight={'medium'}
                                            color={'text.secondary'}
                                            textTransform={'capitalize'}
                                        >
                                            {t('420')}
                                        </Text>
                                    </Box>
                                    <Box
                                        as={'button'}
                                        display={'block'}
                                        onClick={() => {
                                            setIsFeedbackMenu(true)
                                            setSelectedFeedback(0)
                                        }}
                                        py={1.5}
                                        px={3}
                                        _hover={{
                                            backgroundColor: '#F5F5F5',
                                        }}
                                        cursor={'pointer'}
                                        borderRadius={'4px'}
                                    >
                                        <Text
                                            fontSize={'sm'}
                                            fontWeight={'medium'}
                                            color={'text.secondary'}
                                            textTransform={'capitalize'}
                                        >
                                            {t('9477')}
                                        </Text>
                                    </Box>
                                    <Box
                                        display={'block'}
                                        onClick={() => logoutHandler()}
                                        py={1.5}
                                        px={3}
                                        _hover={{
                                            backgroundColor: '#F5F5F5',
                                        }}
                                        cursor={'pointer'}
                                        borderRadius={'4px'}
                                    >
                                        <Text
                                            fontSize={'sm'}
                                            fontWeight={'medium'}
                                            color={'text.secondary'}
                                            _firstLetter={{ textTransform: 'uppercase' }}
                                            textTransform={'lowercase'}
                                        >
                                            {t('1446')}
                                        </Text>
                                    </Box>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                </Flex>
            </Box>
        )
    } else {
        return null
    }
}
